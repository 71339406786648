import styled from '@emotion/styled';
import { flexColContainerStyles } from 'commonStyles';
import { TEXT_ALIGN, TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  COLOR_SHADE,
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_JUSTIFY_CONTENT,
  CSS_POSITION,
  FONT_FAMILY,
  getThemeFontFamily,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputPaddingShape,
  getThemeMainColor,
  getThemeNeutralColor,
  getThemeSimulatorColor,
  ITheme,
  MAIN_COLOR,
} from 'theme';
import { IColor } from 'types';
import hideable from 'utils/hideable';

export const Wrapper = styled.section<ITheme & IColor>(
  ({ background, ...props }) => ({
    background,
    display: CSS_DISPLAY.FLEX,
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
    width: '100%',
    padding: `${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)} 20px`,
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      display: CSS_DISPLAY.NONE,
    },
  })
);

export const SimulatorNoCodeBlockWrapper = styled.div((props) => ({
  padding: '0 20px',
  width: '100%',
  height: '100%',
  paddingBottom: getThemeGutter(props, TSHIRT_SIZE.XL),
}));

export const StyledWrapper = styled.div({
  margin: '0 auto',
  maxWidth: '1024px',
  width: '100%',
});

const TitlesContainer = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  alignItems: CSS_ALIGN.CENTER,
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.L),
  'h2, h3, h4, h5, h6': {
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
  },
  p: {
    textAlign: CSS_ALIGN.CENTER,
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.XXS),
  },
}));

export const ScraperContainer = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  maxWidth: '1024px',
  width: '100%',
  margin: '0 auto',
  height: 'auto',
  padding: `${getThemeGutter(props, TSHIRT_SIZE.S)} ${getThemeGutter(
    props,
    TSHIRT_SIZE.S
  )} ${getThemeGutter(props, TSHIRT_SIZE.XS)}`,
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  boxShadow: '0 0 24px 0 rgb(0 0 0 / 10%)',
  borderRadius: '12px',
  background: getThemeMainColor(props),
  [MEDIA_QUERIES.TABLET]: {
    height: 'auto',
    width: '732px',
  },
}));

export const ScraperHeaderContainer = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  width: '100%',
  margin: `0 auto ${getThemeGutter(props, TSHIRT_SIZE.S)}`,
  height: 'auto',
  paddingBottom: getThemeGutter(props, TSHIRT_SIZE.S),
  alignItems: CSS_ALIGN.CENTER,
  borderBottom: `1px solid ${getThemeNeutralColor(
    props,
    COLOR_SHADE.INVISIBLE
  )}`,
  svg: {
    height: '26px',
    width: 'auto',
    marginRight: getThemeGutter(props, TSHIRT_SIZE.L),
  },
}));

export const HeaderTextValues = styled.div<ITheme>((props) => ({
  marginRight: getThemeGutter(props, TSHIRT_SIZE.L),
  fontWeight: 500,
  fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
  color: getThemeMainColor(props, MAIN_COLOR.BLACK),
  span: {
    fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
    fontWeight: 400,
  },
}));

export const ScraperContentContainer = styled.div({
  display: CSS_DISPLAY.FLEX,
  width: '100%',
  height: 'auto',
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
});

export const CSVWrapper = styled.div({
  display: CSS_DISPLAY.FLEX,
  width: '53%',
  minHeight: '200px',
  height: 'auto',
});

export const CSVContainer = styled.div({
  width: '100%',
});

export const Title = styled.div<ITheme>((props) => ({
  fontSize: getThemeFontSize(props, TEXT_TYPE.H3),
  lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H3),
  color: getThemeNeutralColor(props, COLOR_SHADE.DEEP),
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
  fontFamily: getThemeFontFamily(props),
}));

export const JSONWrapper = styled.div({
  display: CSS_DISPLAY.FLEX,
  width: '45%',
  maxWidth: '408px',
  minHeight: '200px',
  height: 'auto',
});

export const JSONContainer = styled.div({
  width: '100%',
});

export const TableContainer = styled.div({
  display: CSS_DISPLAY.BLOCK,
  width: 'auto',
  height: 'auto',
});

export const Table = styled.table<ITheme>((props) => ({
  display: CSS_DISPLAY.BLOCK,
  overflowX: 'auto',
  whiteSpace: 'nowrap',
  borderCollapse: 'collapse',
  paddingBottom: getThemeInputPaddingShape(props, TSHIRT_SIZE.L),
  tr: {
    height: '32px',
    borderBottom: `1px solid ${getThemeNeutralColor(
      props,
      COLOR_SHADE.INVISIBLE
    )}`,
  },
  scrollbarColor: `${getThemeSimulatorColor(props)} ${getThemeMainColor(
    props
  )}`,
  '::-webkit-scrollbar': {
    height: '6px',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '4px',
    backgroundColor: getThemeSimulatorColor(props),
    backgroundClip: 'padding-box',
  },
}));

export const TableHeader = styled.thead<ITheme>((props) => ({
  th: {
    paddingRight: getThemeInputPaddingShape(props, TSHIRT_SIZE.L),
    fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
    fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P2),
    color: getThemeMainColor(props, MAIN_COLOR.BLACK),
    fontWeight: 500,
  },
}));

export const TableBody = styled.tbody<ITheme>((props) => ({
  td: {
    paddingRight: getThemeInputPaddingShape(props, TSHIRT_SIZE.L),
    fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
    fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P2),
    color: getThemeNeutralColor(props, COLOR_SHADE.SECONDARY),
    fontWeight: 400,
  },
}));

export const JsonCodeContainer = styled.div<ITheme>((props) => ({
  ...flexColContainerStyles,
  background: getThemeNeutralColor(props, COLOR_SHADE.DEEP),
  width: '100%',
  marginTop: getThemeGutter(props, TSHIRT_SIZE.S),
  borderRadius: '6px',
  height: '308px',
  padding: '20px 6px 6px',
}));

export const JsonCodeField = styled.pre<ITheme>((props) => ({
  ...flexColContainerStyles,
  width: '100%',
  color: getThemeMainColor(props),
  fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
  overflow: 'scroll',
  overflowX: 'hidden',
  overflowWrap: 'break-word',
  scrollbarGutter: 'stable',
  position: CSS_POSITION.RELATIVE,
  scrollbarColor: `${getThemeSimulatorColor(props)} ${getThemeNeutralColor(
    props,
    COLOR_SHADE.DEEP
  )}`,
  '::-webkit-scrollbar': {
    width: '6px',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '4px',
    backgroundColor: getThemeSimulatorColor(props),
  },
  '::-webkit-scrollbar-track': {
    marginRight: '10px',
  },
  span: {
    fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
    textAlign: TEXT_ALIGN.LEFT,
  },
  div: {
    display: 'none !important',
  },
}));

export const HideableTitles = hideable(TitlesContainer);
